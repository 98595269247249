import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface DeliveryIndexItem {
  id: number;
  detInternalIndex: number;
  clientName: string;
  clientId: number;
  reference: string;
  plannedDate: string;
  articleNumber: string;
  quantity: number;
  unit: string;
  statusId: number;
  originalSource: string;
  errors?: { message: string; errors: Record<string, string[]> };
}

export const clientConceptDeliveriesIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<DeliveryIndexItem[]>>> => {
  const response = await getPaginated(
    `client/${clientId}/delivery/concept`,
    data
  );

  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};

export const conceptDeliveriesIndex = async (
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<DeliveryIndexItem[]>>> => {
  const response = await getPaginated(`delivery/concept`, data);

  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
